<template>
  <div id="app">
    <transition name="slide-fade" mode='out-in'>
        <component :is="layout">
            <router-view />
        </component>
    </transition>
    <div id="loading-bg">
      <div class="loading-wrapper">
        <div class="loading">
          <div class="circle-wrapper">
              <div class="circle">
                <span></span>
                <span></span>
                <span></span>
              </div>
          </div>
            <img  class="logo" style="max-width: 100px;" :src="assets.favIcon" alt="Logo" />
        </div>
      </div>
  </div>
  </div>
 
</template>
<script>
import '@/assets/css/loader.css'
import { device } from "@/libs/vue-mobile-detection";
import icons from '@/mixins/icons';

const LayoutMobile = () => import('./layouts/mobile/LayoutDesign.vue')
const LayoutDesktop = () => import('./layouts/desktop/LayoutDesign.vue')

export default {
  name: 'App',
  components: {  
    LayoutMobile,
    LayoutDesktop
  },
  data() {
      return {
          assets: icons,
      }
  },
  computed: {
    layout() {
      return `layout-${device()}`
    },
  },
  created() {
    // Add class to html tag
    document.documentElement.classList.add(device())
  },
  beforeCreate() {
    let user = this.$store.getters['auth/userData'];
      if (user == null) {
          // Fetch content from JSON
          this.$store.dispatch('auth/updateIsUserLoggedIn',false)
      } else {
          // Load content from Vuex Store
          this.$store.dispatch('auth/updateIsUserLoggedIn',true)
      }
  },
  mounted() {
        let currency = process.env.VUE_APP_CURRENCY_CODE
        let country = process.env.VUE_APP_COUNTRY
        let locale = process.env.VUE_APP_LANGUAGE

        this.switchLocale(country, locale, currency);
    },
}
</script>

<style>
#app {
  font-family: Tahoma,Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
