<template>
  <div class="toastification">
    <div class="d-flex flex-row">
      <div class="mx-auto text-center">
        <b-avatar :variant="variant" size="1.5rem" class="mr-25 flex-shrink-0">
            <font-awesome-icon :icon="icon" class="" />
        </b-avatar>
        <span v-if="title" class="mb-0 font-weight-bolder toastification-title text-uppercase" :class="`text-${variant}`"
          v-text="title" />
      </div>
      <div class="m-0">
        <span class="cursor-pointer toastification-close-icon ml-auto" @click="$emit('close-toast')">
          <font-awesome-icon icon="fa-solid fa-xmark" class="fa-xl text-secondary mr-50" />
        </span>
      </div>
    </div>

    <div class="d-flex flex-row">
      <div class="toastification-content">
        <small v-if="text" class="d-inline-block text-body" v-html="text" />
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}
.toastification-title {
  color: inherit;
}
.toastification-content{
  background-color: #E6E6E6;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  color: #00335D;
}
.toastification-content small{
  max-width: 80%;
  font-size: 0.7rem;
  color: #00335D!important;
}
</style>
