import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import content from './content'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    content,
    auth
  },
})
