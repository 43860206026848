import Vue from 'vue'
import {ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import App from './App.vue'
import VueMobileDetection from 'vue-mobile-detection'
import store from './store'
import i18n from '@/libs/i18n'
import router from './router'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './helper'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import '@/libs/toastification'
import AOS from 'aos'
import 'aos/dist/aos.css'

library.add(fas)
library.add(far)
library.add(fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// import core styles
require('./@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
// createApp(App).use(store).use(router).use(i18n).mount('#app')

// Composition API
Vue.use(VueMobileDetection)
import { device } from "@/libs/vue-mobile-detection"
require('@/assets/scss/' + device() + '.scss')

document.addEventListener("DOMContentLoaded", function() {
  setTimeout(function() { AOS.refresh(); }, 500);
});