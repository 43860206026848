import axios from "@/libs/axios.js"
import ability from "@/libs/acl/ability.js"
import { initialAbility, authAbility } from '@/libs/acl/config'

export default {
    namespaced: true,
    state: {
      userData : null,
      isUserLoggedIn: false,
    },
    getters: {
        userData: (state) => {
            return state.userData??JSON.parse(localStorage.getItem('userData'));
        },
        isUserLoggedIn: (state) => {
            return state.isUserLoggedIn;
        },
    },
    mutations: {
        UPDATE_USER_DATA(state, val) {
            state.userData = val
            localStorage.setItem('userData', JSON.stringify(val))
        },
        UPDATE_IS_USER_LOGGED_IN(state, val){
            state.isUserLoggedIn = val
        },
    },
    actions: {
        fetchUserData({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `${process.env.VUE_APP_BASE_URL}/api/v1/member/campaign/profile`,
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                }) 
                .then((response) => {
                    commit('UPDATE_USER_DATA', response.data);

                    resolve(response);
                })
                .catch((error) => {
                    reject(error)
                    commit('UPDATE_IS_USER_LOGGED_IN', false);
                    commit('UPDATE_USER_DATA', null);
                    localStorage.removeItem('token')
                })
            })
        },
        updateIsUserLoggedIn({ commit },val) {

            commit('UPDATE_IS_USER_LOGGED_IN', val);

        },
        logout({ commit }) {

            commit('UPDATE_USER_DATA', null);

            localStorage.removeItem('token')

            commit('UPDATE_IS_USER_LOGGED_IN', false);

            ability.update(initialAbility)
        },
        login({ commit },val) {

            localStorage.setItem('token', val);

            commit('UPDATE_IS_USER_LOGGED_IN', true);

            ability.update(authAbility);

        },
    },
  }
