export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]


export const authAbility = [
  {
      action: 'read',
      subject: 'Member',
  },
  {
      action: 'read',
      subject: 'Auth',
  },
]

export const _ = undefined
