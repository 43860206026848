import Vue from 'vue'
import i18n from './libs/i18n';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.mixin({
    methods: { 
        toastSuccess: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-check',
                    variant:'success',
                },
            })
        },
        toastError: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-xmark',
                    variant:'danger',
                },
            })
        },
        toastWarning: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-exclamation',
                    variant:'warning',
                },
            })
        },
        toastInfo: function (title,text="") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    text: text,
                    icon: 'fa-solid fa-info',
                    variant:'info',
                },
            })
        },
        errorHandler(error){

            let {status, data} = error.response;

            switch(status){
                case 400:
                    this.toastError('Fail',data.errors);break;
                case 422:
                    this.toastError('Fail',data.errors.join('<br>'));break;
                default:
                    this.toastError('Fail',error.message);
                    break;
            }
        },
        switchLocale(country, locale, currency) {
            localStorage.setItem('country', country.toLowerCase())
            localStorage.setItem('language', locale.toLowerCase())
            localStorage.setItem('currency', currency.toUpperCase())

            // Set currency locale
            i18n.locale = locale.toLowerCase();
        },
        resetObject: function(object) {
            Object.keys(object).forEach( (key) => {
                if( object[key] !== null && typeof object[key] == 'object'){
                     Object.keys(object[key]).forEach( (key2) => {
                        object[key][key2] = null;
                     });
                }else{
                    object[key] = null;
                }
            });
        },
    },
})