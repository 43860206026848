import { device } from "@/libs/vue-mobile-detection";
export default {
    decorTree: require('@/assets/images/' + device() + '/main/decor-tree.png'),
    spinTitle: require('@/assets/images/' + device() + '/main/spin-title.png'),
    point: require('@/assets/images/' + device() + '/main/select-point.png'),
    segments: require('@/assets/images/' + device() + '/main/segments-sg.png'),
    wheelBoard: require('@/assets/images/' + device() + '/main/wheel-board.png'),
    decorSnowflakesLeft: require('@/assets/images/' + device() + '/main/decor-snowflakes-left.png'),
    decorSnowflakesRight: require('@/assets/images/' + device() + '/main/decor-snowflakes-right.png'),
    logoWithShadow: require('@/assets/images/' + device() + '/logo/inz9-logo-color-dropshadow.png'),
    favIcon: require('@/assets/images/' + device() + '/logo/inz9-favicon.png'),
    logo: require('@/assets/images/' + device() + '/logo/logo.png'),
    username: require('@/assets/images/desktop/icon/my-account-icon.png'),
    headerBack: require('@/assets/images/mobile/icon/back-icon.png'),
    giftOne:require('@/assets/images/' + device() + '/prize/gift-1.png'),
    giftTwo:require('@/assets/images/' + device() + '/prize/gift-2.png'),
    giftThree:require('@/assets/images/' + device() + '/prize/gift-3.png'),
    giftFour:require('@/assets/images/' + device() + '/prize/gift-4.png'),
    giftFive:require('@/assets/images/' + device() + '/prize/gift-5.png'),
    giftSix:require('@/assets/images/' + device() + '/prize/gift-6-sg.png'),
    giftSeven:require('@/assets/images/' + device() + '/prize/gift-7-sg.png'),
    giftEight:require('@/assets/images/' + device() + '/prize/gift-8-sg.png'),
}
